import React from "react"
import styled from "styled-components"
import { FlexDiv } from "../design-system/FlexDiv"
// import blogPostImg from '../images/blogPostImg4.jpg'
import { Link } from "gatsby"
// import blogPostImg5 from '../images/blogPostImg5.jpg'
import blogPostImg22 from "../images/blogPostImg2.jpg"
import blogPostImg3 from "../images/blogPostImg3.jpg"
import blogPostImg4 from "../images/blogPostImg4.jpg"
import blogPostImg6 from "../images/pandemicera.jpg"

const BlogDetails = styled.p`
  color: gray;
`

const BlogTitle = styled.p`
  font-size: 1.25em;
  font-family: CentraNo2-Medium;
  margin-bottom: 0px !important;
  margin-right: 4em !important;
`
const blogExcerpt = styled.p``

const GalleryWrapper = styled.div`
  margin: auto;
  padding: 2em 0;
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  max-width: 90%;
`

const CTATitle = styled.h3`
  font-family: CentraNo2-Bold;
  letter-spacing: 1px;
  font-size: 2.25rem;
  color: #212121;
  text-align: center;
  margin: 0.5em 0;
`

const CardText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.8em;
  margin: 1em 0;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
`

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const GivesBackWrapper = styled.div`
  margin: auto;
  padding: 5em;
  display: inline-flex;
`

const GivesBackTitle = styled.h3`
  font-family: CentraNo2-Bold;
  font-size: 2em;
  margin-bottom: 0.5em;
`

const GivesBackText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 1.15em;
  line-height: 1.75em;
  font-style: italic;
`
const GivesBackText2 = styled.p`
  font-family: CentraNo2-Book;
  font-size: 1em;
  line-height: 1.25em;
`

const ButtonLearnMore = styled.button`
  background: #000;
  color: #fff;
  padding: 15px;
  border: none;
  margintop: 20px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  align-self: center;
`

const ButtonLearnMore2 = styled.button`
  background: #fff;
  color: #000;
  padding: 15px;
  border: none;
  margintop: 20px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  align-self: center;
  border: 1.5px solid #000;
`
const BackSearchLink = styled.a`
  font-family: CentraNo2-Book;
  font-weight: 500;
  margin: 1em 0 2em;
  cursor: pointer;
`

export const FourWaysProfit: React.FunctionComponent = () => {
  return (
    <GivesBackWrapper className="blogPostWrapper">
      <FlexDiv style={{ maxWidth: "75%", margin: "auto" }} className="blogPostContainer">
        <FlexDiv
          style={{
            flexFlow: "column",
            maxWidth: "65%",
            marginRight: "7.5%",
            flex: "10",
          }}
          className="blogInner"
        >
          <GivesBackTitle className="blogTitle">
            Four Ways Location Affects Profits
          </GivesBackTitle>
          <GivesBackText2 style={{ margin: 0 }} className="dateText">
            Dec 13, 2019, 08:00am EST
          </GivesBackText2>

          <GivesBackText className="authorBio">
            Anthony Tropea, President & Co-Founder of Ellicott Realty Group,
            overseeing the company's portfolio: Ellicott Realty, Ellicott
            Financial, & MacKenzie Hall.
          </GivesBackText>
          <img
            src={blogPostImg4}
            style={{
              width: "100%",
              margin: "auto",
              padding: "1em 0",
            }}
          />

          <GivesBackText2>
            Practically everyone in business has heard that one of the most
            critical factors to ensure a profitable business is location,
            location, location. Before I address profitability factors, let’s
            get into the fundamental decisions that act as the foundation upon
            which you build your business, almost literally, from the ground up
            to transform it from a startup to a thriving success.
          </GivesBackText2>

          <GivesBackText2>
            As you go deeper into your business plan, the budget for real estate
            expenses gets very real as you consider location in terms of
            infrastructure, taxes, utilities, zoning law, environmental
            regulations and growth potential. These affect not only
            brick-and-mortar businesses but online ones, as well. For example,
            where will the inventory go — in your house or apartment? Are there
            laws about that? Do you have room for inventory? No. Suddenly,
            commercial real estate also becomes part of the online puzzle.
          </GivesBackText2>

          <GivesBackText2>
            I recently discovered Business Development Bank of Canada (BDC) has
            several online articles that guide potential business owners through
            the necessary hoops of starting a business, including one
            specifically about choosing a location where you’ll find more about
            those fundamentals presented above.
          </GivesBackText2>

          <GivesBackText2>
            Back in the day, I had to learn those ropes as I went along — a bit
            of trial and error. But I took it one thing at a time and, often,
            one day at a time. And that’s what it took: time. You may be anxious
            to get your business going in the perfect spot before everyone else
            does, but skipping these fundamentals toward establishing the best
            location helps you avoid costly pitfalls. Take the time you need.
            Find the right resources to guide you in finance, real estate, legal
            and other applicable areas. It’s worth it. You’ll see.
          </GivesBackText2>
          <GivesBackText2>
            <strong>Suppliers And Employees</strong>
          </GivesBackText2>
          <GivesBackText2>
            No matter the size of the business, even if you are strictly an
            online store, your distribution warehouse or office should be
            located near your suppliers so you can re-stock quickly. With
            suppliers around the corner, face-to-face meetings with them become
            an option. This can help strengthen communication and business
            relations with your supplier.
          </GivesBackText2>

          <GivesBackText2>
            What is the worksite’s proximity to public transportation? If the
            location is close to public transit, prospective employees may be
            more interested in a position with your business over another.
          </GivesBackText2>

          <GivesBackText2>
            <strong> Address Branding</strong>
          </GivesBackText2>

          <GivesBackText2>
            If your business offers products or services outside of a strictly
            online presence, the importance of location goes beyond your
            suppliers and employees. Still keeping suppliers and employees in
            mind, location becomes a big part of your branding. For example,
            when you hear that a business is in SoHo, New York, or Yorkville,
            Toronto, immediately a perception of that business comes to mind.
            The prestige associated with those areas automatically benefits a
            business located there. Consider your target audience and place
            yourself in an area that is composed of your target demographic.
          </GivesBackText2>

          <GivesBackText2>
            We have to remember that the relationship between a landlord and a
            tenant is ultimately a relationship like any other: We can
            strengthen relationships through proper decorum, common courtesy and
            respect. In this time of great hardship, let us be mindful that we
            are indeed in this together, and commit to treating one another with
            the respect that all humankind deserves.{" "}
          </GivesBackText2>
          <GivesBackText2>
            <strong> Traffic And Retention </strong>
          </GivesBackText2>

          <GivesBackText2>
            You also want to balance your business site between high-traffic
            visibility and convenient accessibility without overextending one’s
            finances, thereby “breaking the bank” on your lease or purchase. I
            suggest considering a location within a developed infrastructure. A
            sufficiently developed area affords both your employees and your
            clients the ability to utilize conveniences located nearby, and, in
            turn, those conveniences potentially become clients and customers.
            My partner and I deliberately considered the same scenario as we
            evaluated where to place our financial entity.
          </GivesBackText2>
          <GivesBackText2>
            Inevitably, potential clients or customers will naturally come
            across your business as they go throughout their day, increasing the
            likelihood of unsolicited and organic interactions with your
            business. Then there’s the matter of repeat business. No matter how
            good you believe your products or services to be, if your location
            is either inaccessible or inconveniently situated, the majority of
            people won’t make an effort to visit.
          </GivesBackText2>
          <GivesBackText2>
            <strong> Competition</strong>
          </GivesBackText2>
          <GivesBackText2>
            Although we covered the perfect address for better consumer
            perception and visibility in a well-developed, amenity-rich area,
            let’s not leave out the competition. Don’t forget to do your
            research. It is unwise to position yourself in a location that has
            too many businesses similar to your own. However, all is not lost if
            the competition around your business is inflated, while the location
            is ideal.
          </GivesBackText2>
          <GivesBackText2>
            You could consider looking for a building or structure with
            distinctive and notable historical or aesthetic characteristics
            unlike those around it to differentiate. I believe contrasted
            appearance feeds into the competitive advantage and increases the
            likelihood of profitability for your company. In addition to
            providing superior services or products, such contrast could be
            pivotal to your branding, success and, ultimately, your
            profitability.
          </GivesBackText2>
          <GivesBackText2>
            If you seriously consider these factors for your company and
            business, you’ve conquered half the battle toward achieving a
            thriving, profitable business.
          </GivesBackText2>
          
        </FlexDiv>
          <FlexDiv style={{ display: "flex", flexDirection: "column" }}>
          <FlexDiv
            style={{
              flex: "0.8",
              flexFlow: "column",
              paddingTop: "245px",
              marginLeft: "5%",
            }}
            className="shortBlogSideBar relatedBlogPosts fourWaysSideBar"
          >
          <h2 className="mobileOnly">
            Continue Reading
          </h2>
            <div
              style={{ background: "#fff", maxWidth: "90%" }}
              className="socialCard"
            >
              <Link
                to="/pandemicera"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "#000",
                  fontFamily: "CentraNo2-Medium",
                }}
                className="imgLinkWrapper"
              >
                <img
                  src={blogPostImg6}
                  style={{ width: "100%" }}
                  className="socialImg"
                />
              </Link>
              <CardText>
                <FlexDiv>
                  <FlexDiv style={{ flexFlow: "column", alignSelf: "center" }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {" "}
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontFamily: "CentraNo2-Medium",
                          }}
                          className="relatedBlogPostsTitle"
                        >
                          The Pandemic-Era Trends Impacting Real Estate
                          Profitability
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "gray",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#212121",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          Whether you're bullish or bearish on the financial
                          impacts of Covid-19, this is undoubtedly a time to
                          profit on real estate if you know how to navigate the
                          market.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: "0.5em" }}
                      >
                        <a
                          href="https://www.forbes.com/sites/forbesrealestatecouncil/2021/12/28/the-pandemic-era-trends-impacting-real-estate-profitability/?sh=79d1c5a68d4a"
                          target="_blank"
                          style={{
                            margin: "1em 0",
                            textDecoration: "none",
                            color: "gray",
                          }}
                           className="readArticleForbes"
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv>
                  <Link
                    to="/propmanagement"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontFamily: "CentraNo2-Medium",
                      paddingTop: "50px",
                    }}
                    className="imgLinkWrapper"
                  >
                    <img
                      src={blogPostImg22}
                      style={{ width: "100%" }}
                      className="socialImg"
                    />
                  </Link>
                  <FlexDiv style={{ flexFlow: "column", alignSelf: "center" }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {" "}
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontFamily: "CentraNo2-Medium",
                          }}
                        >
                          How Property Management Can Make It Through A Pandemic
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "gray",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#212121",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          In this time of great hardship, let us be mindful that
                          we are indeed in this together, and commit to treating
                          one another with the respect that all humankind
                          deserves.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: "0.5em" }}
                      >
                        <a
                          href="https://www.forbes.com/sites/forbesrealestatecouncil/2020/07/13/how-property-management-can-make-it-through-a-pandemic/"
                          target="_blank"
                          style={{
                            margin: "1em 0",
                            textDecoration: "none",
                            color: "gray",
                          }}
                          className="readArticleForbes"
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv>
                  <Link
                    to="/truecolours"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontFamily: "CentraNo2-Medium",
                      paddingTop: "50px",
                    }}
                    className="imgLinkWrapper"
                  >
                    <img
                      src={blogPostImg3}
                      style={{ width: "100%" }}
                      className="socialImg"
                    />
                  </Link>
                  <FlexDiv style={{ flexFlow: "column", alignSelf: "center" }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {" "}
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontFamily: "CentraNo2-Medium",
                          }}
                        >
                          It Is Time For Our True Colors To Illustrate True
                          Leadership And Connection
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "gray",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#212121",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          As a business owner, evaluate these key facets of your
                          life when creating goals this year.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: "0.5em" }}
                      >
                        <a
                          href="http://www.forbes.com/sites/forbesrealestatecouncil/2020/05/28/it-is-time-for-our-true-colors-to-illustrate-true-leadership-and-connection/"
                          target="_blank"
                          style={{
                            margin: "1em 0",
                            textDecoration: "none",
                            color: "gray",
                          }}
                          className="readArticleForbes"
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </div>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </GivesBackWrapper>
  )
}
