import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { FourWaysProfit } from 'components/FourWaysProfit'
import { Contact } from 'components/Contact'

const FourWaysProfitPage: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <FourWaysProfit />
      <Contact />
    </EllicotLayout>
  )
}

export default FourWaysProfitPage
